import React, { FC, PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { CheckExitPromptProvider } from 'Src/components/modals/useCheckExitPrompt';
import { ClockoutPromptProvider } from 'Src/components/modals/useClockoutPrompt';
import { GlobalSearchProvider } from 'Src/components/useGlobalSearch';
import { CurrentAuthRequestProvider } from 'Src/feature/auth-request/useCurrentAuthRequest';
import { CardReaderProvider } from 'Src/feature/card-reader/useCardReader';
import { CurrentCheckStoreProvider } from 'Src/feature/check/useCurrentCheck';
import { TerminalLocationLoggerProvider } from 'Src/feature/context-logger/TerminalLocationLoggerProvider';
import { HasChangesProvider } from 'Src/feature/has-changes/useHasChanges';
import { HasChangesPromptProvider } from 'Src/feature/has-changes/useHasChangesPrompt';
import { CurrentMenuScreenProvider } from 'Src/feature/menu/useCurrentMenuScreen';
import { NotificationEventDispatcher } from 'Src/feature/notification/NotificationEventDispatcher';
import { CurrentOperatorProvider } from 'Src/feature/operator/useCurrentOperator';
import { CurrentPaymentProvider } from 'Src/feature/payment/useCurrentPayment';
import { IsClockedInProvider } from 'Src/feature/terminal/useIsClockedIn';
import { ToastStoreProvider } from 'Src/feature/toasts/useToast';
import { SocketProvider } from 'Src/network/useSocket';
import { AppConfigStoreProvider } from 'Src/stores/useAppConfig';

interface GlobalProviderProps {
  queryClient: QueryClient;
}

const GlobalProvider: FC<PropsWithChildren<GlobalProviderProps>> = ({ children, queryClient }) => (
  <ToastStoreProvider>
    <SocketProvider>
      <QueryClientProvider client={queryClient}>
        <TerminalLocationLoggerProvider>
          <CardReaderProvider>
            <AppConfigStoreProvider>
              <HasChangesProvider>
                <HasChangesPromptProvider>
                  {/* provide a current checkUI model to all check routes w/ a "checkId" route param */}
                  <CurrentCheckStoreProvider>
                    <CheckExitPromptProvider>
                      <CurrentOperatorProvider>
                        <ClockoutPromptProvider>
                          <IsClockedInProvider>
                            <CurrentPaymentProvider>
                              <CurrentAuthRequestProvider>
                                <CurrentMenuScreenProvider>
                                  <GlobalSearchProvider>
                                    <NotificationEventDispatcher/>
                                    {children}
                                  </GlobalSearchProvider>
                                </CurrentMenuScreenProvider>
                              </CurrentAuthRequestProvider>
                            </CurrentPaymentProvider>
                          </IsClockedInProvider>
                        </ClockoutPromptProvider>
                      </CurrentOperatorProvider>
                    </CheckExitPromptProvider>
                  </CurrentCheckStoreProvider>
                </HasChangesPromptProvider>
              </HasChangesProvider>
            </AppConfigStoreProvider>
          </CardReaderProvider>
        </TerminalLocationLoggerProvider>
      </QueryClientProvider>
    </SocketProvider>
  </ToastStoreProvider>
);

export { GlobalProvider };
