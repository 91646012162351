import React, { FC, PropsWithChildren, useMemo } from 'react';

import { useTerminal } from 'Src/feature/terminal/useTerminal';

import { LoggerContext, useLogger } from './useLogger';

const TerminalLocationLoggerProvider: FC<PropsWithChildren> = ({ children }) => {
  const log = useLogger();
  const { data: terminalLocation } = useTerminal.query.get();
  const locationId = terminalLocation?.location.id;
  const terminalId = terminalLocation?.terminal.id;
  const locationIdLogger = useMemo(() => {
    if (locationId && terminalId) return log.with('locationId', locationId).with('terminalId', terminalId);
    else if (locationId && !terminalId) return log.with('locationId', locationId);
    else if (!locationId && terminalId) return log.with('terminalId', terminalId);
    return log;
  }, [locationId, log, terminalId]);
  return <LoggerContext.Provider value={locationIdLogger}>{children}</LoggerContext.Provider>;
};

export { TerminalLocationLoggerProvider };